<template>
  <div class="content">
    <Page class="main">
      <div class="content_after_sale">
        <div class="pro_main_breadcrumb">
          <span @click="toUserInfo">{{ $t('Home') }}</span>
          <img src="~img/down_icon.svg" />
          <span @click="toOrderMain">{{ $t('Orders') }}</span>
          <img src="~img/down_icon.svg" />
          <b>{{ $t('After Sale') }}</b>
        </div>
        <div class="order_main_card">
          <div class="evaluate_left" style="height: 500px">
            <div class="pro_img">
              <JLImg :src="order.productCoverImg" />
            </div>
            <div class="pro_name">{{ order.productName }}</div>
            <div class="pro_pic">{{ JLTools.forPrice(order.productAmount) }} x{{ order.purchaseNum }}</div>
            <!--                        <div class="pro_sx">-->
            <!--                            <span>Color:white</span>-->
            <!--                            <span>Memory:128GB</span>-->
            <!--                        </div>-->
          </div>
          <div class="evaluate_right" style="height: 500px">
            <div class="evaluate_right_item">
              <div class="txt">{{ $t('Refund Type') }}</div>
              <div class="main">
                <button :class="{ xuan: refund.disposeMode === 'REFUND' }" @click="changeDisposeMode('REFUND')">{{ $t('REFUND') }}</button>
                <button :class="{ xuan: refund.disposeMode === 'RETURNREFUND' }" @click="changeDisposeMode('RETURNREFUND')">{{ $t('REFUND&RETURN') }}</button>
              </div>
            </div>
            <div class="evaluate_right_item">
              <div class="txt">{{ $t('Submitted Quantity') }}</div>
              <div class="main">
                <div class="cart_quantity_item">
                  <img src="~img/jianhao.svg" @click="updateRefundGoodsNum(-1, '-')" />
                  <input v-model="refund.purchaseNum" @input="updateRefundGoodsNum(refund.purchaseNum, '')" />
                  <img src="~img/jiahao.svg" @click="updateRefundGoodsNum(1, '+')" />
                </div>
              </div>
            </div>
            <div class="evaluate_right_item">
              <div class="txt">{{ $t('Refund Amount') }}</div>
              <div class="main">
                <div class="cart_applyamount_item">
                  <input v-model="refund.applyAmount" @input="change" readonly />
                </div>
              </div>
            </div>
            <!--                        <div class="evaluate_right_item">-->
            <!--                            <div class="txt">Reason</div>-->
            <!--                            <div class="main">-->
            <!--                                <select>-->
            <!--                                    <optgroup>Please Select</optgroup>-->
            <!--                                    <option>The product does not match the actual description</option>-->
            <!--                                    <option>Brought the wrong thing</option>-->
            <!--                                </select>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <div class="evaluate_right_item">
              <div class="txt">{{ $t('Reason') }}<span class="color_red">*</span></div>
              <div class="main">
                <textarea v-model="refund.reason" maxlength="200"></textarea>
                <span>{{ (refund.reason && refund.reason.length) || 0 }}/200</span>
              </div>
            </div>
            <div class="evaluate_right_item">
              <div class="txt">{{ $t('Upload Pictures') }}<span class="color_red">*</span></div>
              <div class="main">
                <div class="img_up">
                  <imgUpload fileCount="3" @img-list="uploadImg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="order_main_card evaluate_right_card">
          <button @click="createRefundOrder(order)">{{ $t('Confirm Submission') }}</button>
        </div>
      </div>
    </Page>
  </div>
</template>

<script>
  import Page from '@/components/Page'
  import imgUpload from '@/components/uploadImg'
  import { reactive, toRefs, onMounted, getCurrentInstance } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import api from '@/api'
  import JLTools from '@/util/JLTools'

  export default {
    name: 'afterSale',
    components: { Page, imgUpload },
    setup(props, context) {
      const state = reactive({
        orderId: '',
        order: {},
        refund: {
          disposeMode: 'REFUND'
        }
      })

      const { proxy } = getCurrentInstance()
      const router = useRouter()
      const route = useRoute()

      onMounted(() => {
        let orderArr = JSON.parse(route.params.order)
        state.orderId = orderArr[0].orderId
        if (JLTools.isNull(state.orderId)) {
          return false
        }
        getOrderDetailById(state.orderId)
      })

      const change = () => {
        if (state.refund.purchaseNum === state.order.num) {
          if (state.refund.applyAmount > state.order.sum) {
            state.refund.applyAmount = state.order.sum
          }
        } else {
          let sum = JLTools.accMul(state.refund.purchaseNum, state.order.dproductAmount)
          if (state.refund.applyAmount > sum) {
            state.refund.applyAmount = sum
          }
        }
      }
      /**
       * 查询订单详情
       * @param id
       */
      const getOrderDetailById = (id) => {
        api.pay.getOrderDetailByIdReq(id)
          .then((res) => {
            state.order = res
            state.order.purchaseNum = 1
            state.refund.applyAmount = state.order.amount
            if (!JLTools.isNull(res.orderSpecs)) {
              state.refund.purchaseNum = state.order.purchaseNum = res.orderSpecs[0].purchaseNum
              state.order.productAmount = res.orderSpecs[0].amount
              state.order.specId = res.orderSpecs[0].specId
              state.order.orderSpecId = res.orderSpecs[0].id
              state.order.specName = res.orderSpecs[0].specName
              state.order.specAmount = res.orderSpecs[0].amount
              state.order.dproductAmount = parseFloat(state.order.amount / state.order.purchaseNum).toFixed(2)
              state.order.sum = state.order.amount
              state.order.num = state.order.purchaseNum
            }
          })
      }

      /**
       * 修改退单方式
       * @param type 处理方式[退款,退货退款]
       */
      const changeDisposeMode = (type) => {
        state.refund.disposeMode = type
        proxy.$forceUpdate()
      }

      /**
       * 修改退货数量
       * @param num
       * @param type
       */
      const updateRefundGoodsNum = (num, type) => {
        // debugger;
        if (JLTools.isNull(type)) {
          let reg = /[^\d]/g
          state.refund.purchaseNum = num.replace(reg, '')
        } else {
          state.refund.purchaseNum = JLTools.accAdd(state.refund.purchaseNum, num)
        }
        // console.log(state.refund.purchaseNum);
        if (state.refund.purchaseNum > state.order.purchaseNum || parseFloat(state.refund.purchaseNum) === 0) {
          state.refund.purchaseNum = state.order.purchaseNum
        }
        if (state.refund.purchaseNum === state.order.num) {
          state.refund.applyAmount = state.order.sum
        } else {
          state.refund.applyAmount = JLTools.accMul(state.refund.purchaseNum, state.order.dproductAmount)
        }
      }

      const uploadImg = (list) => {
        let img = ''
        list.forEach((item, index) => {
          if (index == list.length - 1) {
            img = img + item
          } else {
            img = img + item + '|'
          }
        })
        proxy.refund.evidenceImgs = img
      }

      /**
       * 创建退单
       * @param order 订单
       */
      const createRefundOrder = (order) => {
        let data = {}
        if (JLTools.isNull(state.refund.reason)) {
          proxy.$toast(proxy.$t('请输入原因！'))
          return false
        }
        data.applyAmount = state.refund.applyAmount
        data.disposeMode = state.refund.disposeMode
        data.mobile = order.mobile
        data.nickname = order.nickname
        data.orderId = order.id
        data.orderNo = order.no
        data.orderSpecId = order.orderSpecId
        data.paymentId = order.paymentId
        data.paymentNo = order.paymentNo
        data.productId = order.productId
        data.productName = order.productName
        data.purchaseNum = state.refund.purchaseNum
        data.reason = state.refund.reason
        data.receipted = true
        data.specAmount = order.specAmount
        data.specId = order.specId
        data.specName = order.specName
        data.userId = order.userId
        data.viewed = false
        data.evidenceImgs = state.refund.evidenceImgs
        data.productCoverImg = order.productCoverImg
        // console.log(data);

        let title = proxy.$t('Notice')
        let msg = proxy.$t('Confirm application refund')
        proxy.$Message.confirm({
          title: title,
          msg: msg,
          onOk: () => {
            createRefundOrderReq(data)
          }
        })
      }

      /**
       * 创建退单请求
       * @param data
       */
      const createRefundOrderReq = (data) => {
        // console.log('申请退单：=====',JSON.stringify(data))
        api.pay.createRequestRefundReq(data)
          .then((res) => {
            // let title = proxy.$t('Notice');
            // let msg = proxy.$t('Application has been submitted');
            proxy.$toast(proxy.$t('Application has been submitted'))
            toOrderMain()
          })
          .catch((error) => {
            let title = proxy.$t('Error')
            proxy.$Message.confirm({
              title: title,
              msg: error.title
            })
          })
      }

      const toOrderMain = () => {
        router.replace({
          name: 'ORDERMAIN',
          params: { orderId: state.orderId }
        })
      }

      const toUserInfo = () => {
        router.replace({
          name: 'USERINFO'
        })
      }

      return {
        ...toRefs(state),
        createRefundOrder,
        createRefundOrderReq,
        changeDisposeMode,
        updateRefundGoodsNum,
        uploadImg,
        toOrderMain,
        toUserInfo,
        change
      }
    }
  }
</script>

<style scoped>
  .content_after_sale {
    width: var(--minWidth);
    margin: 0 auto;
  }

  .pro_main_breadcrumb {
    float: left;
    width: 100%;
    padding: 12px 0;
    font-size: 13px;
  }

  .pro_main_breadcrumb span {
    float: left;
    line-height: 30px;
  }

  .pro_main_breadcrumb img {
    float: left;
    margin: 9px;
    width: 12px;
    height: 12px;
    transform: rotate(270deg);
  }

  .pro_main_breadcrumb b {
    float: left;
    line-height: 30px;
  }

  .order_main_card {
    float: left;
    width: 100%;
    background: #fff;
    margin-bottom: 20px;
    box-shadow: 0 0 15px #eee;
  }

  .order_main_card .pro_item .pro_img {
    float: left;
    width: 70px;
    height: 70px;
  }

  .order_main_card .pro_item .pro_info .pro_sx {
    float: left;
    width: 100%;
    margin-top: 10px;
    color: #999;
  }

  .order_main_card .order_zhuangt .icon {
    float: left;
    width: 50px;
    height: 50px;
    margin: 20px calc(50% - 25px) 0;
  }

  .order_main_card .order_zhuangt .order_num {
    float: left;
    width: 100%;
    text-align: center;
    height: 50px;
    line-height: 50px;
    color: #999;
  }

  .order_main_card .order_zhuangt .txt {
    float: left;
    width: 100%;
    text-align: center;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
  }

  .order_main_card .order_zhuangt .btn {
    float: left;
    height: 30px;
    width: 40%;
    margin: 0 30%;
    line-height: 28px;
    border: 1px solid var(--btnBgColor);
    color: var(--btnBgColor);
    text-align: center;
  }

  .order_main_card .order_zhuangt .btn:hover {
    cursor: pointer;
    background: var(--btnBgColor);
    color: #fff;
    transition: background 0.3s;
  }

  .order_main_card .order_liuc {
    float: left;
    width: calc(100% - 300px);
    margin: 25px 0;
    padding: 0 20px;
  }

  .order_main_card .order_liuc .order_liuc_txt {
    float: left;
    width: 100%;
    line-height: 50px;
    color: #999;
  }

  .evaluate_left {
    float: left;
    width: 350px;
    height: 400px;
    border-right: 1px solid #e4e4e4;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .evaluate_left .pro_img {
    width: 150px;
    height: 150px;
  }

  .evaluate_left .pro_name {
    padding: 0 30px;
    text-align: center;
    margin-top: 10px;
  }

  .evaluate_left .pro_pic {
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
    font-weight: bold;
  }

  .evaluate_left .pro_sx {
    text-align: center;
    color: #999;
    margin-top: 10px;
  }

  .evaluate_left .pro_sx span {
    margin-left: 10px;
  }

  .evaluate_left .pro_sx span:first-child {
    margin-left: 0px;
  }

  .evaluate_right {
    float: left;
    height: 400px;
    width: 850px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
  }

  .evaluate_right .evaluate_right_item {
    float: left;
    width: 100%;
    margin-top: 20px;
  }

  .evaluate_right .evaluate_right_item:first-child {
    margin-top: 0px;
  }

  .evaluate_right .evaluate_right_item .txt {
    float: left;
    width: 130px;
    line-height: 30px;
    color: #999;
  }

  .evaluate_right .evaluate_right_item .main {
    float: right;
    width: calc(100% - 130px);
  }

  .evaluate_right .evaluate_right_item .main textarea {
    float: left;
    width: 100%;
    height: 120px;
    border: 1px solid #c4c4c4;
    padding: 10px;
  }

  .evaluate_right .evaluate_right_item .main .img_star img {
    width: 24px;
    height: 24px;
    margin-top: 3px;
  }

  .evaluate_right .evaluate_right_item .main .img_up {
    float: left;
    width: 100%;
    margin-top: 10px;
  }

  .evaluate_right .evaluate_right_item .main .img_up .case {
    float: left;
    position: relative;
    width: 70px;
    height: 70px;
    margin-left: 10px;
    padding: 5px;
    border: 1px solid #c4c4c4;
  }

  .evaluate_right .evaluate_right_item .main .img_up .case input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
  }

  .evaluate_right .evaluate_right_item .main .img_up .case:first-child {
    margin-left: 0px;
  }

  .evaluate_right .evaluate_right_item .main .img_up .case:hover {
    cursor: pointer;
    background: #f5f5f5;
  }

  .evaluate_right .evaluate_right_item .main button {
    float: left;
    height: 30px;
    border: 1px solid #c4c4c4;
    background: #fff;
    padding: 0 8px;
    margin-left: 15px;
  }

  .evaluate_right .evaluate_right_item .main button:first-child {
    margin-left: 0px;
  }

  .evaluate_right .evaluate_right_item .main button.xuan {
    border: 1px solid var(--btnBgColor);
    color: var(--btnBgColor);
  }

  .evaluate_right .evaluate_right_item .main button:hover {
    cursor: pointer;
  }

  .evaluate_right .evaluate_right_item .main select {
    float: left;
    padding: 0 10px;
    height: 30px;
    border: 1px solid #c4c4c4;
  }

  .evaluate_right_card {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
  }

  .evaluate_right_card button {
    width: 150px;
    height: 40px;
    margin-right: 15px;
    background: var(--btnBgColor);
    color: #fff;
  }

  .evaluate_right_card button:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  .cart_quantity_item {
    float: left;
    background: #fff;
  }

  .cart_quantity_item img {
    float: left;
    padding: 5px;
    width: 26px;
    height: 26px;
    text-align: center;
    border: 1px solid #ccc !important;
  }

  .cart_quantity_item img:hover {
    cursor: pointer;
    background: #eee;
  }

  .cart_quantity_item input {
    float: left;
    text-align: center;
    width: 40px;
    height: 26px;
    border: 1px solid #ccc;
    border-left: 0px;
    border-right: 0px;
  }

  .cart_applyamount_item {
    float: left;
    background: #fff;
  }

  .cart_applyamount_item input {
    float: left;
    text-align: center;
    width: 92px;
    height: 26px;
    border: 1px solid #ccc;
  }
</style>
